<template>
  <v-container>
    <v-btn class="mb-2" color="secondary" @click="$router.push('/email')">
      Email
    </v-btn>
    <client-table v-bind="props" :update-route="true"></client-table>
  </v-container>
</template>
<script>

import ClientTable from '@/components/ClientTable'
import {clientTable} from '@/tables'

export default {
  components: {
    ClientTable
  },
  data () {
    return {
      props: clientTable
    }
  }
}
</script>