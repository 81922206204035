<template>
  <v-container>
    <v-row v-if="obj">
      <v-col>
        <h4>Client</h4>
        <p>{{ obj.name }}</p>
        <h4>Contact email</h4>
        <p>
          <a :href="`mailto:${obj.email}`">{{ obj.email }}</a>
        </p>
      </v-col>
      <v-col>
        <h4>ID</h4>
        <p>{{ obj.id }}</p>
        <h4>UUID</h4>
        <p>{{ obj.uuid }}</p>
      </v-col>
      <v-col>
        <h4>Users</h4>
        <p>{{ obj.users_count }}</p>
        <h4>Machines</h4>
        <p>{{ obj.machines_count }}</p>
        <h4>Creation date</h4>
        <p>{{ obj.creation_date }}</p>
      </v-col>
    </v-row>
    <invoice-table
      class="mb-4"
      v-if="obj"
      v-bind="invoiceProps"
      :can-create="true"
      :create-data="{client: obj.id}"
      :forced-filters="{client: obj.id}"></invoice-table>
    <pricing-table
      class="mb-4"
      v-if="obj"
      v-bind="pricingProps"
      :can-create="true"
      :create-data="{client: obj.id}"
      :forced-filters="{client: obj.id}"></pricing-table>
    <group-table
      class="mb-4"
      v-if="obj"
      v-bind="groupProps"
      :can-create="true"
      :create-data="{client: obj.id}"
      :forced-filters="{client: obj.id}"></group-table>
    <account-table
      class="mb-4"
      v-if="obj"
      v-bind="accountProps"
      :can-create="true"
      :create-data="{client: obj.id}"
      :forced-filters="{client: obj.id}"></account-table>
    <license-table
      class="mb-4"
      v-if="obj"
      v-bind="licenseProps"
      :can-create="true"
      :create-data="{client: obj.id}"
      :forced-filters="{client: obj.id}"></license-table>
    <label-table
      class="mb-4"
      v-if="obj"
      v-bind="labelProps"
      :can-create="true"
      :create-data="{client: obj.id}"
      :forced-filters="{client: obj.id}"></label-table>
    <abtest-table
      class="mb-4"
      v-if="obj"
      v-bind="abtestProps"
      :can-create="true"
      :create-data="{client: obj.id}"
      :forced-filters="{client: obj.id}"></abtest-table>
    <machine-table
      class="mb-4"
      v-if="obj"
      v-bind="machineProps"
      :forced-filters="{client: obj.id}"></machine-table>
  </v-container>
</template>
<script>

import http from '@/http'

import AbtestTable from '@/components/AbtestTable'
import AccountTable from '@/components/AccountTable'
import InvoiceTable from '@/components/InvoiceTable'
import PricingTable from '@/components/PricingTable'
import GroupTable from '@/components/GroupTable'
import LabelTable from '@/components/LabelTable'
import LicenseTable from '@/components/LicenseTable'
import MachineTable from '@/components/MachineTable'
import {
  abtestTable,
  labelTable,
  licenseTable,
  machineTable,
  invoiceTable,
  pricingTable,
  accountTable,
  groupTable,
} from '@/tables'

export default {
  props: ["id"],
  components: {
    AbtestTable,
    AccountTable,
    GroupTable,
    InvoiceTable,
    LabelTable,
    LicenseTable,
    MachineTable,
    PricingTable,
  },
  data () {
    return { 
      abtestProps: abtestTable,
      licenseProps: licenseTable,
      invoiceProps: invoiceTable,
      labelProps: labelTable,
      machineProps: machineTable,
      pricingProps: pricingTable,
      accountProps: accountTable,
      groupProps: groupTable,
      obj: null,
      isLoading: null,
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      let response
      this.isLoading = true
      this.errors = {}
      try {
        response = await http.get(`admin/clients/${this.id}`)
        this.obj = response.data
      } catch (e) {
        this.errors = e.backendErrors
        this.obj = null
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>