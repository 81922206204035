<template>
  <v-data-table
    :headers="headers"
    :items="results.results"
    :options.sync="options"
    :server-items-length="results.count"
    v-if="results"
    :loading="isLoading"
    :footer-props="{itemsPerPageOptions: [5, 25, 50, 100]}"
    @update:options="options = options"
  >
    <template v-slot:item.id="{ item }">
      <router-link :to="{name: `${routerNamespace}.detail`, params: {id: item.id}}">
        {{ item.id }}
      </router-link>
    </template>
    <template v-slot:top>
      <data-table-top
        :form-title="formTitle"
        :item="editedItem"
        :entity-name="entityName"
        :entity-name-plural="entityNamePlural"
        :can-create="true"
        :can-edit="canEdit(item)"
        :can-destroy="canDestroy(item)"
        :no-save="!canSave(item)"
        :query.sync="filters.q"
        @save="save($event)"
        @destroy="destroy($event)"
        @close="close()"
        ref="top"
      >
        <template v-slot:modalForm="slotProps">
          <v-row>
            <v-col
              cols="4"
            >
              <v-select
                v-model="slotProps.item.code"
                :items="[{code: 'active_user', name: 'Active users'}, {code: 'transformed_minute', name: 'Transformed minute'}]"
                item-text="name"
                item-value="code"
                label="Type"
                :error-messages="itemErrors.fieldErrors.code || []"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              v-if="slotProps.item.code == 'transformed_minute'"
            >
              <v-select
                v-model="slotProps.item.transformation"
                :items="transformations"
                item-text="name"
                item-value="id"
                label="Transformation"
                :error-messages="itemErrors.fieldErrors.transformation || []"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="slotProps.item.excluding_tax_price"
                label="Gross price"
                :error-messages="itemErrors.fieldErrors.excluding_tax_price || []"
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="slotProps.item.vat_rate"
                label="VAT rate"
                :error-messages="itemErrors.fieldErrors.vat_rate || []"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </data-table-top>
    </template>
    <template v-slot:item.actions="{ item }" v-if="canEdit(item) || canDestroy(item)">
      <v-icon
        small
        v-if="canEdit(item)"
        class="mr-2"
        @click="editItem(item)"
      >
        {{ $icons.mdiPencil }}
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import http from '@/http'

import DataTable from './DataTable'

export default {
  mixins: [DataTable],
  data () {
    return {
      transformations: [],
    }
  },
  async created () {
    this.transformations = [{id: null, name: "None (default price)"}]
    this.transformations = this.transformations.concat((await http.get('admin/transformations', {params: {o: 'name'}})).data.results)
  },
  methods: {
    parseItemForUpdate (item) {
      if (item.transformation && item.transformation.id) {
        item.transformation = item.transformation.id
      }
      return item
    }
  }

}
</script>