<template>
  <v-textarea
    outlined
    v-model="model"
    :label="label"
    :error-messages="errorMessages">

  </v-textarea>
</template>
<script>

export default {
  props: {
    value: {},
    label: {},
    errorMessages: {},
  },
  data () {
    return {
      model: JSON.stringify(this.value, null, 2),
    }
  },
  methods: {
    cleanValue (v) {
      return JSON.parse(v)
    }
  },
  watch: {
    model (v) {
      this.$emit('input', this.cleanValue(v))
    }
  }
}
</script>